@import "variables";
@import "colors";
@import "./responsive/viewportmixins";
@import "common";
@import "customer";
@import "components";
@import "./mobile/components";

.App-header {
  width: 100%;
  height: 50px;
  position: fixed;
  background-color: #3099ef;
  top: 0;
  z-index: 100;
}

.page {
  position: absolute;
  padding-top: 50px;
  // top: 50px;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
}

.customers-page {
  @extend .page;
  justify-content: center;
}

.single-customer-page {
  @extend .page;
  display: block;
}

.Welcome {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h2 {
  color: #fab11dff;
}

a {
  text-decoration: none;
}

.transition--enabled {
  -moz-transition: all $transitionDuration;
  -webkit-transition: all $transitionDuration;
  transition: all $transitionDuration;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin loader {
  border-style: solid;
  border-color: $loadingBackgroundColor;
  border-top-color: $appColor;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

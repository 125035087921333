@mixin customer-page-containers {
  height: auto;
  @include respond-above(sm) {
    height: 100%;
  }
  padding: 1rem;
  overflow: scroll;
}

.customer-info-container {
  float: left;
  width: 100%;
  @include customer-page-containers;
  @include respond-above(sm) {
    width: calc(100% * 5 / 12);
  }
}

.customer-info-card {
  border: 2px solid $appColor;
  min-height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-action-button {
  height: 2rem;
  min-width: 8rem;
  margin: 2px 0 2px;
  background-color: $appColor;
  color: white;
  border-radius: 4px;
}

.customer-action-button--active {
  background-color: $activeColor;
}

.customer-action-button--disabled {
  pointer-events: none;
  background-color: $disabledColor;
  color: $disabledTextColor;
}

#single-customer-account {
  text-decoration: none;
}

.single-customer__single-account {
  width: 100%;
  height: 4em;
  margin: 0.3em 0 0 0;
  border: 2px solid $appColor;
}

.single-customer__single-account--closed {
  border: 2px solid $dangerBorderColor;
}

.component--active {
  border: 2px solid $activeColor;
  // box-shadow: 4px 12px 12px #eeeeee;
}

.single-customer__all-accounts {
  @extend .single-customer__single-account;
  height: 3em;
}

#account-info-container {
  height: 3em;
  margin-top: 0.5em;
  padding: 0 0.4em 0 0.4em;
  color: black;
}

#customer-info {
  padding: 0.2em;
}

.customer-details-container {
  // float: left;
  width: 100%;
  @include respond-above(sm) {
    width: calc(100% * 7 / 12);
  }
  @include customer-page-containers;
  align-items: flex-start;
  overflow: scroll;
}

.customer-configuration-container {
  padding: 0.5em 0.5em 0 0.5em;
}

.transactions-container {
  min-height: 5vh;
  max-height: 63vh;
  width: 100%;
  @include respond-above(sm) {
    overflow: scroll;
  }
}

.transactions-container--elevated {
  background-color: white;
  width: 100%;
  @include respond-above(sm) {
    box-shadow: 2px 2px 8px #eeeeee;
  }
}

.transaction-details-container {
  @include customer-page-containers;
  width: 100%;
}

.transaction-details-item {
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }

  .elevation {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }

  .action-details-container {
    width: 100%;
    padding: 0.5rem;
    background-color: white;
  }

  .unverified-customer-section {
    border: 0;
    padding: 0 !important;
  }

  footer {
    display: none;
  }
}

.Action {
  width: 80%;
  height: 2.5rem;
  line-height: 2.5rem;
  border-bottom: 1px solid $blueBorderColor;
  text-align: left;
}

.action-list-container {
  min-width: calc(100% * 4 / 12);
  background-color: #0000000d;
  border-right: 2px solid #00000073;
  padding: 0.5rem;
  overflow: scroll;
}

.device-item {
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-details-container {
  width: 80%;
  background-color: #0000000d;
  padding: 1rem;
  overflow: scroll;
}

.danger-button {
  background-color: $dangerBorderColor;
}

.customer-item {
  width: 100%;
  height: 3.5rem;
  margin: 2px;
  background-color: white;
  -webkit-transition: height 1s;
  transition: height 1s;
}

.customer-shortinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes visibility {
  to {
    visibility: visible;
  }
}

.User-expandedinfo {
  animation: visibility 0s 0.5s forwards;
  visibility: hidden;
  margin-top: 1rem;
}

.customer-prepend-label {
  min-width: 10rem;
}

.customer-name {
  visibility: visible;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.account-transactions {
  height: 26em;
  overflow: scroll;
  transition: all 1s ease-out;
}
.loader {
  @include loader;
  border-width: 12px;
  border-top-width: 12px;
  width: 80px;
  height: 80px;
}

.button-loader {
  @include loader;
  border-width: 6px;
  border-top-width: 6px;
  width: 2em;
  height: 2em;
}

.account-container {
  width: 97%;
  padding: 0 28%;
  visibility: visible;
  opacity: 1;
  transition: opacity 1s, visibility 1s;
}

.account-container-hidden {
  visibility: hidden;
  opacity: 0;
  animation: move-account 1s ease-in forwards;
}

@keyframes move-account {
  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.account-component {
  width: 100%;
  -webkit-transition: transform 0.7s ease-out;
  transition: transform 0.7s ease-out;
}

.account-transform {
  -webkit-transform: translateX(-60%);
  transform: translateX(-60%);
}

.AAZZUR-text-color {
  color: #3099ef;
  text-transform: uppercase;
  font-size: 12px;
}

.account-modal {
  max-width: 75%;
}

.unverified-customer-section {
  border: 2px solid #00000026;
  margin-bottom: 2.5em;
}

.dropdown-disable {
  appearance: none;
}

.customer-tab {
  margin-top: 1em;
}

.text-input {
  border: 1px solid blue;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

h5 {
  color: black;
}

.action-button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  padding: 0;
  background-color: transparent;
  appearance: none;
  border: none;
  float: right;
  height: 1.5rem;
  outline: none;
  cursor: pointer;
  :hover {
    box-shadow: 1px 1.5px 4px #eeeeee;
    background-color: $activeAppColor;
  }
  :active {
    box-shadow: 0px 0px 0px;
    background-color: $activeAppColor;
  }
}

footer {
  margin-top: auto;
}

.enable-footer {
  display: flex;
  flex-direction: column;
}

.profile-main-content {
  width: 60%;
  padding-left: 30px;
  overflow: hidden;
  padding-right: 10px;
}

.profile-info {
  padding-right: 20px;
}

.tab-row {
  border: 1px solid #e6e7e4;
  border-radius: 2px;
  padding: 0 !important;
  margin-right: 2px;
}

.scopes-list {
  color: #666;
  margin-right: 7px;
  background: #f9f9f9;
  border-radius: 2px;
  margin-left: 0;
  list-style: none;
  padding: 0;
}

.icon-button {
  padding: 8px;
}

.back-action {
  @include respond-above(sm) {
    display: none;
  }
}
